import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/info_page/Content";

import ThankYouIcon from "icons/thank-you.svg";

const ThankYou = ({ location }) => {
  const { pathname } = location;

  return (
    <Layout static_header={pathname === "/"} hide_contact_section>
      <Content
        icon={<ThankYouIcon />}
        title="Dziękujemy za wysłanie wiadomości"
        text="Skontaktujemy się z Tobą jak najszybciej"
      />
    </Layout>
  );
};

export const Head = () => <Seo title="Dziękujemy | Wólczańska 4" />;

export default ThankYou;
